<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <!-- <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div> -->
    <div style="width: 100%; height: 400px">
      <img src="@/assets/img/zdrugConsult/bannerZdrug.png" alt="" srcset="" style="width: 100%; height: 100%" />
    </div>
    <div class="consultBox">
      <div class="consultTop" @click="goStart">
        <img src="@/assets/img/zdrugConsult/applyIcon.png" alt="" style="width: 28px; height: 28px" />
        <div>开始问诊</div>
      </div>
      <div class="consultCenter">
        <img src="@/assets/img/zdrugConsult/pageCenter.png" alt="" srcset="" style="width: 100%; height: 100%" />
      </div>
      <div class="consultIcon">
        <img src="@/assets/img/zdrugConsult/pageTop.png" alt="" srcset="" style="width: 100%; height: 100%" />
      </div>
      <div class="consultBottom">
        <div>根据每个人的个体差异，智能评估用户身体情况，证同治同，证异治异</div>
        <div style="color: #cd6c0f">探究疾病的本质和病因，预防或治疗相应的疾病。</div>
      </div>
    </div>
    <div style="width: 100%; height: 150px">
      <img src="@/assets/img/zdrugConsult/bottomZdrug.png" alt="" srcset="" style="width: 100%; height: 100%" />
    </div>
    <ft-com></ft-com>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";

export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    return {
      labelPosition: "right",
      resetPassword: {
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
      },
      uploader: null,
      file: "",
      patientType: 1, // 固定值
      organitionId: 0, // 固定值
      keyWord: "",
      pageIndex: 1,
      userInfo: {},
      patientList: [],
      visible: false,
      currentlyAvailable: false,
    };
  },
  methods: {
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    goStart() {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.$router.push("./zDrugStart");
    },
    getList() {
      var _this = this;
      _this.userInfo = this.$store.state.localData.userInfo;
      console.log(_this.keyWord);
      _this.patientDomain.getPatientList(
        this.patientType,
        _this.userInfo.organizationId,
        _this.userInfo.id,
        "",
        "",
        _this.keyWord,
        _this.pageIndex,
        function (data) {
          _this.patientList = data.data.results;
          for (var i = 0; i < _this.patientList.length; i++) {
            var f = _this.patientList[i].addTime.split("T");
            _this.patientList[i].addTime = f[0] + " " + f[1].slice(0, 8);
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";

.consultBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 66px;
  margin-bottom: 82px;
}
.consultCenter {
  width: 471px;
  height: 34px;
}
.consultIcon {
  width: 20px;
  height: 17px;
  margin: 26px 0;
}
.consultTop {
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
  padding: 20px 120px;
  border-radius: 45px;
  background: linear-gradient(0deg, #dc9450, #fabf88);
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.consultBottom {
  font-size: 18px;
  text-align: center;
}
.banner {
  width: 100%;
  height: 160px;
}

.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.banner-text {
  float: left;
}

.banner-text h3 {
  padding-top: 35px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 14px;
  color: #ffffff;
  margin-top: 20px;
}

.banner-text p a {
  color: #ffffff;
  font-size: 14px;
  margin: 0 5px;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
